import React from 'react';
import LinkButton from '../linkbutton';
import './styles.scss';

const Onboarding = props => {
    return (
        <section className="onboarding">
            <div className="onboarding__container">
                <h2 className="onboarding__heading">Därför ska du välja Delfi</h2>
                <div className="onboarding__col-container">
                    <div className="onboarding__col">
                        <p>Personlig och professionell kundservice</p>
                    </div>
                    <div className="onboarding__col">
                        <p>30 års erfarenhet och kunskap av branschen</p>
                    </div>
                    <div className="onboarding__col">
                        <p>Oberoende och ledande leverantör</p>
                    </div>
                    <div className="onboarding__col">
                        <p>Alltid aktuell och tillgänglig information</p>
                    </div>
                    <div className="onboarding__col">
                        <p>För stora och små kunder inom dagligvarubranschen</p>
                    </div>
                    <div className="onboarding__col">
                        <p>Digitala tjänster som ger dig insikter</p>
                    </div>
                </div>
            </div>
            <div className="onboarding__footer">
                <LinkButton url="/demo">Boka demo</LinkButton>
            </div>            
        </section>
    );
};

export default Onboarding;
